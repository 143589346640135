import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { getImage } from 'gatsby-plugin-image';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import Seo from '../components/seo';
import { LandingPageLayout } from '../components/layout';
import { Section } from '../components/layout/Section';
import { UmoPromote } from '../components/UmoPromote';

const PageForkliftDriversInstruction = ({ pageContext, data }) => {
  const { bannerImageCall } = data;
  const { t } = useTranslation(['forklift-drivers-instruction']);
  const bannerImage = getImage(bannerImageCall);
  return (
    <>
      <Seo title={t('meta.title')} description={t('meta.description')} lang={pageContext.language} />

      <LandingPageLayout
        bannerImage={bannerImage}
        bannerTitle={t('banner.title')}
        bannerDescription={t('banner.description')}
      >
        <Section heading={t('heading.gesamtverantwortung')}>
          <Typography>{t('content.gesamtverantwortung')}</Typography>
          <Typography>{t('content.gesamtverantwortung2')}</Typography>
        </Section>

        <Section heading={t('heading.forklift')}>
          <Typography>{t('content.forklift')}</Typography>
          <Typography>{t('content.forklift2')}</Typography>
          <Typography>{t('content.forklift3')}</Typography>
          <Section headingVariant="h3" heading={t('heading.inhaltInstruction')}>
            <ol>
              {[
                t('content.inhaltInstruction1'),
                t('content.inhaltInstruction2'),
                t('content.inhaltInstruction3'),
                t('content.inhaltInstruction4'),
                t('content.inhaltInstruction5'),
                t('content.inhaltInstruction6'),
                t('content.inhaltInstruction7'),
              ].map((text) => {
                return (
                  <ListItem style={{ display: 'list-item' }} key={text}>
                    <ListItemText>{text}</ListItemText>
                  </ListItem>
                );
              })}
            </ol>
            <Typography>{t('content.inhaltInstruction8')}</Typography>
            <Typography>{t('content.inhaltInstruction9')}</Typography>
            <Typography>{t('content.inhaltInstruction10')}</Typography>
            <Typography>{t('content.inhaltInstruction11')}</Typography>
          </Section>
        </Section>

        <Section heading={t('heading.umoForklift')}>
          <Typography>{t('content.umoForklift')}</Typography>

          <UmoPromote />
        </Section>
      </LandingPageLayout>
    </>
  );
};
export default PageForkliftDriversInstruction;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    bannerImageCall: file(relativePath: { eq: "forkliftdriver.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
      }
    }
  }
`;
